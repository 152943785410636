import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography } from "@mui/material";
import "./index.css";

const validUsernames = [
  "AFernandes",
  "AMartins",
  "ASharda",
  "BMorrow",
  "BRuhl",
  "CGrimes",
  "DJiang",
  "DRigo",
  "DThomson",
  "DYap",
  "ESmith",
  "EYii",
  "GTrudinger",
  "HAlhusaini",
  "HFadil",
  "HLee",
  "HParikh",
  "HSafaei",
  "JButler",
  "JGloury",
  "JOsmond",
  "JWaters",
  "KStevens",
  "LDouglass",
  "LLawrence",
  "NZhu",
  "OAlmubaied",
  "PAgrawal",
  "SForbes",
  "SKhehra",
  "SKilybayev",
  "TLequoc",
  "TStone",
  "WElgohary",
];

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    if (validUsernames.includes(username) && password === "12345") {
      localStorage.setItem("isAuthenticated", "true");
      onLogin(true);
    } else {
      setError("Incorrect username or password");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginTop: "100px",
        textAlign: "center",
        border: "2px solid #000",
        padding: "20px",
        backgroundColor: "white",
      }}>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={handleKeyPress}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
        fullWidth
        margin="normal"
      />
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      <Button variant="contained" color="primary" onClick={handleLogin}>
        Login
      </Button>
    </Container>
  );
};

export default Login;
