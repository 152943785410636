import { Buffer } from "buffer";

export function generateToken() {
  const decodeAuth =
    process.env.REACT_APP_PROJECTWORKS_API_CONSUMER_KEY +
    ":" +
    process.env.REACT_APP_PROJECTWORKS_API_CONSUMER_SECRET;
  var encodedAuth = Buffer.from(decodeAuth).toString("base64");
  return encodedAuth;
}

export async function callApi(method, url) {
  try {
    const token = generateToken();
    const response = await fetch(
      "https://api.projectworksapp.com/api/v1.0" + url,
      {
        method: method,
        headers: {
          Authorization: "Basic " + token,
        },
      }
    );

    // Check if response is empty or null
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return { status: response.status, data: data };
  } catch (error) {
    console.error("Error calling API:", error);
    throw error;
  }
}
