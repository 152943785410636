import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Logo from "./Logo.png";
import { callApi } from "./helper";
import classNames from "classnames";
import "./index.css";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "./Loader";
import JSZip from "jszip";
import Login from "./Login";

import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [files, setFiles] = useState([]);
  const [folderId, setFolderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [folderPrepared, setFolderPrepared] = useState(false);
  const [folderLink, setFolderLink] = useState(""); // State to store the folder's web view link
  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected dropdown option
  const [currentIndex, setCurrentIndex] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const [searchInput, setSearchInput] = useState(""); // State to store the search input
  const [showOpenButton, setShowOpenButton] = useState(false); // State to control the visibility of the "Open Folder" button
  const [isLoadingData, setIsLoadingData] = useState(true); // State to manage the loading state of initial data fetching
  const [remarks, setRemarks] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showDragDropBox, setShowDragDropBox] = useState(false);
  const [showUploadMoreButton, setShowUploadMoreButton] = useState(false);

  const handleLogin = (status) => {
    setIsAuthenticated(status);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectData = [];
        const promises = [];

        for (let page = 1; page <= 100; page++) {
          const url = `/Projects?page=${page}&pageSize=50000&includeCustomFields=true`;
          promises.push(callApi("GET", url));
        }

        const responses = await Promise.all(promises);
        for (const res of responses) {
          projectData.push(...res.data);
        }
        setTimeout(() => {
          setIsLoading(false); // Set loading state to false after data fetching completes
        }, 3000);

        setJobs(projectData);
        setIsLoadingData(false); // Set isLoadingData to false after data fetching completes
      } catch (error) {
        console.error("Error fetching projects:", error);
        // Handle errors
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on initial render

  // Move the second useEffect call inside the first one to avoid conditional call
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectData = [];
        const promises = [];

        for (let page = 1; page <= 100; page++) {
          const url = `/Projects?page=${page}&pageSize=50000&includeCustomFields=true`;
          promises.push(callApi("GET", url));
        }

        const responses = await Promise.all(promises);
        for (const res of responses) {
          projectData.push(...res.data);
        }

        setJobs(projectData);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // Always call useDropzone unconditionally
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        const extractedFiles = [];
        // Process each dropped file
        for (const file of acceptedFiles) {
          if (file.type === "application/zip") {
            // If the dropped file is a zip folder, extract its contents
            const zip = new JSZip();
            const zipFile = await zip.loadAsync(file);
            for (const fileName in zipFile.files) {
              const extractedFile = await zipFile.files[fileName].async("blob");
              // Create a new File object for each extracted file
              const extracted = new File([extractedFile], fileName);
              extractedFiles.push(extracted);
            }
          } else {
            // If the dropped file is not a zip folder, add it directly
            extractedFiles.push(file);
          }
        }
        // Add extracted files to the state
        setFiles((prevFiles) => [...prevFiles, ...extractedFiles]);
      } catch (error) {
        console.error("Error processing dropped files:", error);
        alert("An error occurred while processing dropped files.");
      }
    },
    noClick: !folderPrepared,
    noKeyboard: !folderPrepared,
  });

  const removeFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  // Function to handle successful upload
  const handleSuccessUpload = () => {
    // Show success message
    setShowSuccessMessage(true);
    // Hide success message after 5 seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000);
    // Show the "Upload More Files" button
    setShowUploadMoreButton(true);
  };

  const onJobSelected = (job) => {
    setCurrentJob(job);

    // Extract MelbOrAdel from CustomFields
    const MelbOrAdel = job.CustomFields[0].Value; // Assuming CustomFields[0] exists
    // Concatenate ProjectNumber with "M" or "A" based on condition
    const FullNumber =
      job.ProjectNumber +
      (MelbOrAdel === null || MelbOrAdel === "1" ? "M" : "A");
    setFolderName(FullNumber);

    // Update TextField with projectNumber and projectName
    setSearchInput(`${job.ProjectNumber} - ${job.ProjectName}`);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    switch (option) {
      case "CAD":
        await prepareFolderStructure();
        break;
      case "Shop Drawings":
        await prepareShopDrawingsFolder();
        break;
      case "Photos":
        await preparePhotosFolder();
        break;
      case "Geotechnical":
        await prepareGeotechnicalFolder();
        break;
      case "L.P.O.D":
        await prepareLPODFolder();
        break;
      default:
        break;
    }
  };

  const prepareFolderStructure = async () => {
    if (folderName.length !== 6 || remarks.length === 0) {
      alert("Please enter folder name and remarks");
      return;
    }
    console.log(folderName);
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://dragdrop-ff8y.onrender.com/prepare-folder",
        // "http://localhost:3001/prepare-folder",
        { folderName, remarks } // Pass remarks to the server
      );
      setIsLoading(false);
      if (response.data.folderId) {
        setFolderId(response.data.folderId);
        setFolderPrepared(true);
      } else {
        alert("Failed to prepare folder structure.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error preparing folder:", error);
      alert("An error occurred while preparing the folder.");
    }
  };

  const prepareShopDrawingsFolder = async () => {
    if (folderName.length !== 6 || remarks.length === 0) {
      // Ensure remarks are provided
      alert("Please enter folder name and remarks");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://dragdrop-ff8y.onrender.com/prepare-shop-drawings-folder",
        // "http://localhost:3001/prepare-shop-drawings-folder",
        { folderName, remarks } // Pass remarks to the server
      );
      setIsLoading(false);
      if (response.data.folderId) {
        setFolderId(response.data.folderId);
        setFolderPrepared(true);
      } else {
        alert("Failed to prepare Shop Drawings folder structure.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error preparing Shop Drawings folder:", error);
      alert("An error occurred while preparing the Shop Drawings folder.");
    }
  };

  const preparePhotosFolder = async () => {
    if (folderName.length !== 6 || remarks.length === 0) {
      // Ensure remarks are provided
      alert("Please enter folder name and remarks");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://dragdrop-ff8y.onrender.com/prepare-photos-folder",
        // "http://localhost:3001/prepare-photos-folder",
        { folderName, remarks } // Pass remarks to the server
      );
      setIsLoading(false);
      if (response.data.folderId) {
        setFolderId(response.data.folderId);
        setFolderPrepared(true);
      } else {
        alert("Failed to prepare Photos folder structure.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error preparing Photos folder:", error);
      alert("An error occurred while preparing the Photos folder.");
    }
  };

  // Function to prepare Geotechnical folder structure
  const prepareGeotechnicalFolder = async () => {
    if (folderName.length !== 6) {
      alert("Please enter folder name");
      return;
    }
    setIsLoading(true);
    try {
      // Make a POST request to prepare the Geotechnical folder structure
      const response = await axios.post(
        "https://dragdrop-ff8y.onrender.com/prepare-geotechnical-folder",
        // "http://localhost:3001/prepare-geotechnical-folder",
        { folderName }
      );
      setIsLoading(false);
      if (response.data.folderId) {
        setFolderId(response.data.folderId);
        setFolderPrepared(true);
      } else {
        alert("Failed to prepare Geotechnical folder structure.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error preparing Geotechnical folder:", error);
      alert("An error occurred while preparing the Geotechnical folder.");
    }
  };

  // Function to prepare LPOD folder structure
  const prepareLPODFolder = async () => {
    if (folderName.length !== 6) {
      alert("Please enter folder name");
      return;
    }
    setIsLoading(true);
    try {
      // Make a POST request to prepare the LPOD folder structure
      const response = await axios.post(
        "https://dragdrop-ff8y.onrender.com/prepare-lpod-folder",
        // "http://localhost:3001/prepare-lpod-folder",
        { folderName }
      );
      setIsLoading(false);
      if (response.data.folderId) {
        setFolderId(response.data.folderId);
        setFolderPrepared(true);
      } else {
        alert("Failed to prepare LPOD folder structure.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error preparing LPOD folder:", error);
      alert("An error occurred while preparing the LPOD folder.");
    }
  };

  // Update handleSend function to call handleSuccessUpload on successful upload
  const handleSend = async () => {
    setIsLoading(true);
    try {
      // Extract files from the zip folder
      const extractedFiles = [];
      for (let file of files) {
        if (file.type === "application/zip") {
          // If the file is a zip folder, extract its contents
          const zip = new JSZip();
          const zipFile = await zip.loadAsync(file);
          for (const fileName in zipFile.files) {
            const extractedFile = await zipFile.files[fileName].async("blob");
            extractedFiles.push(new File([extractedFile], fileName));
          }
        } else {
          // If the file is not a zip folder, add it directly
          extractedFiles.push(file);
        }
      }

      // Upload the extracted files
      for (let file of extractedFiles) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folderId", folderId);

        const response = await axios.post(
          " https://dragdrop-ff8y.onrender.com/upload",
          // "http://localhost:3001/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // Set the folder link state with the received link
        setFolderLink(response.data.folderLink);
      }

      // Call handleSuccessUpload when upload is successful
      handleSuccessUpload();

      // Clear files and reset other states as needed...
      setFiles([]);
      setIsLoading(false);
      setShowOpenButton(true); // Show the "Open Folder" button
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      alert("An error occurred.");
    }
  };

  const filteredJobs = jobs.filter(
    (job) =>
      job.ProjectName.toLowerCase().includes(searchInput.toLowerCase()) ||
      job.ProjectNumber.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Function to handle the click event of the "Upload More Files" button
  const handleUploadMore = () => {
    // Reset input field values and selected dropdown option
    setSearchInput("");
    setRemarks("");
    setSelectedOption("");
    // Clear the files state
    setFiles([]);
    // Hide the drag and drop box
    setShowDragDropBox(false);
    // Hide the "Upload More Files" button
    setShowUploadMoreButton(false);
  };

  // If the user is not authenticated, render the Login component
  // if (!isAuthenticated) {
  //   return <Login onLogin={handleLogin} />;
  // }

  return (
    <div className="background-transition">
      {/* Render placeholder content or loading indicator while images are loading */}
      {isLoadingData ? (
        <div className="loader-container">
          <Loader />
        </div>
      ) : (
        <div id="app-container">
          <Container
            maxWidth="sm"
            sx={{
              border: "2px solid #000",
              padding: "20px",
              backgroundColor: "white",
            }}>
            <div
              style={{
                justifyContent: "center",
                position: "relative",
                top: "20",
              }}>
              <img src={Logo} alt="logo" width="100%" />
            </div>
            <div>
              <TextField
                style={{ marginBottom: "10px" }}
                fullWidth
                label="Enter name or number"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />

              {searchInput &&
                searchInput.length >= 4 &&
                filteredJobs.filter((job) => !job.ProjectNumber.includes("."))
                  .length > 0 && ( // This line excludes any project numbers containing a dot.
                  <Box
                    sx={{
                      maxHeight: 200,
                      overflowY: "auto",
                      mb: 2,
                      backgroundColor: "#f5f5f5",
                    }}>
                    <List>
                      {filteredJobs
                        .filter((job) => !job.ProjectNumber.includes("."))
                        .map(
                          (
                            job // Also apply the same filter here for safety.
                          ) => (
                            <ListItem
                              key={job.ProjectID}
                              onClick={() => onJobSelected(job)}
                              button>
                              <ListItemText
                                primary={`${job.ProjectNumber} - ${job.ProjectName}`}
                              />
                            </ListItem>
                          )
                        )}
                    </List>
                  </Box>
                )}
              <TextField
                style={{ marginBottom: "10px" }}
                fullWidth
                label="Enter remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            <Select
              value={selectedOption}
              onChange={handleOptionChange}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}>
              <MenuItem value="" disabled>
                What file are you uploading?
              </MenuItem>
              <MenuItem value="CAD" onClick={() => handleOptionClick("CAD")}>
                CAD
              </MenuItem>
              <MenuItem
                value="Shop Drawings"
                onClick={() => handleOptionClick("Shop Drawings")}>
                Shop Drawings
              </MenuItem>
              <MenuItem
                value="Photos"
                onClick={() => handleOptionClick("Photos")}>
                Photos
              </MenuItem>
              <MenuItem
                value="Geotechnical"
                onClick={() => handleOptionClick("Geotechnical")}>
                Geotechnical
              </MenuItem>
              <MenuItem
                value="L.P.O.D"
                onClick={() => handleOptionClick("L.P.O.D")}>
                L.P.O.D
              </MenuItem>
            </Select>
            {folderPrepared && (
              <>
                <Box
                  {...getRootProps()}
                  sx={{
                    border: "2px dashed #007bff",
                    padding: "20px",
                    marginBottom: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: isDragActive ? "#e3f2fd" : "#f5f5f5",
                  }}>
                  <input {...getInputProps()} />
                  <Typography>
                    Drag 'n' drop files here, or click to select files
                  </Typography>
                </Box>
                <Box
                  sx={{
                    maxHeight: 200,
                    overflowY: "auto",
                    mb: 2,
                    backgroundColor: "#f5f5f5",
                    display: files.length > 0 ? "block" : "none",
                  }}>
                  <List>
                    {files.map((file) => (
                      <ListItem
                        key={file.name}
                        onClick={() => removeFile(file.name)}
                        button>
                        <ListItemText primary={file.name} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </>
            )}
            {files.length > 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSend}
                disabled={isLoading}
                fullWidth>
                {isLoading ? <CircularProgress size={24} /> : "Upload Files"}
              </Button>
            )}
            {showUploadMoreButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadMore}
                fullWidth>
                Upload More Files
              </Button>
            )}
          </Container>
        </div>
      )}

      <div>
        {showSuccessMessage && (
          <div
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              backgroundColor: "green",
              padding: 10,
              borderRadius: 5,
              zIndex: 999,
            }}>
            <Typography variant="body1" style={{ color: "white" }}>
              Files uploaded successfully!
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
